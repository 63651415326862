import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "app/store/userSlice";
import { jwtService } from "app/auth/jwtService";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

/**
 * Form Validation Schema
 */

const defaultValues = {
    username: "",
    password: ""
};

function SignInPage() {
    const dispatch = useDispatch();
    const { control, handleSubmit } = useForm({
        defaultValues: defaultValues
    });
    const errorMessage = useSelector(state => state.user.errorMessage);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function onSubmit({ username, password }) {
        if (!username || !password) {
            dispatch(setErrorMessage("Παρακαλώ εισάγετε Username και Password"));
            return;
        }

        jwtService
            .signInWithEmailAndPassword(username, password)
            .then((user) => {
                // No need to do anything, user data will be set at app/auth/AuthContext
            })
            .catch((error) => {
                // Update the error message state
                dispatch(setErrorMessage(error.message));
            });
    }

    return (
        <Grid
            container
            component="main"
            sx={{ minHeight: '90vh' }}
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: isMobile ? 4 : 8,
                        px: isMobile ? 2 : 4,
                        height: isMobile ? '100vh' : 'auto',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/logo/Ypoyrgeio1555.svg`}
                            alt="logo"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </Box>

                    <Typography
                        variant="h4"
                        sx={{ fontWeight: 'extrabold', textAlign: 'center', mb: 6 }}
                    >
                        Είσοδος Χρήστη
                    </Typography>
                    <form
                        name="loginForm"
                        noValidate
                        style={{ width: '100%' }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Button
                            variant="contained"
                            fullWidth={isMobile}
                            className="mt-4 rounded-full bg-orange-500 hover:bg-orange-500 text-white flex justify-center items-center"
                            aria-label="Σύνδεση"
                            size="large"
                            style={{
                                textTransform: 'capitalize',
                                width: isMobile ? '100%' : '40%',
                                height: isMobile ? 'auto' : '48px',
                                padding: isMobile ? '12px 24px' : '12px 32px',
                                fontSize: isMobile ? '16px' : '16px',
                                margin: isMobile ? '0' : '0 auto',
                                display: 'block'
                            }}
                            onClick={() => {
                                window.open(process.env.REACT_APP_TAXIS_LOGIN, '_blank');
                            }}
                        >
                            Σύνδεση
                        </Button>




                        {errorMessage && (
                            <Typography
                                sx={{ mt: 4, mb: 4, color: 'red', textAlign: 'center' }}
                                variant="body2"
                            >
                                {errorMessage}
                            </Typography>
                        )}
                    </form>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <img src={`${process.env.PUBLIC_URL}/logo/espalogo204.jpg`} alt="logo" style={{ maxWidth: '100%', height: 'auto' }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default SignInPage;
