import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
    getThematicByParentId,
    getAllCarriers,
    getQnaSearchResults,
    getThematicByCarrierId,
    getGeographiesByCarrierId
} from "app/Api Calls/KnowledgebaseCalls";

export const fetchCarriers = createAsyncThunk('knowledgebase/fetchCarriers', async () => {
    const response = await getAllCarriers();
    const modifiedResponse = response.map(carrier => {
        if (carrier.id === 12) {
            return {
                ...carrier,
                title: 'Αίτημα αποστολής ηχ/μένης κλήσης'
            };
        }
        return carrier;
    });
    return {original: response, modified: modifiedResponse};
});

export const fetchGeographiesByCarrierId = createAsyncThunk('knowledgebase/fetchGeographiesByCarrierId', async (carrierId) => {
    const response = await getGeographiesByCarrierId(carrierId);
    return {carrierId, geographies: response};
});

export const fetchThematicsByCarrierId = createAsyncThunk('knowledgebase/fetchThematicsByCarrierId', async (carrierId) => {
    const response = await getThematicByCarrierId(carrierId);
    const publishedThematics = response.filter(thematic => thematic.isActive);
    return {carrierId, thematics: publishedThematics};
});

export const fetchThematicsByParentId = createAsyncThunk('knowledgebase/fetchThematicsByParentId', async (parentId) => {
    const response = await getThematicByParentId(parentId);
    const publishedThematics = response.filter(thematic => thematic.isActive);
    return {parentId, thematics: publishedThematics};
});

export const fetchQnaSearchResults = createAsyncThunk(
    'knowledgebase/fetchQnaSearchResults',
    async ({page, size, qnaSearchRequestBody}, {getState}) => {
        const {knowledgeBase} = getState();
        const {qnaCache} = knowledgeBase;
        const cacheKey = `${qnaSearchRequestBody.thematicId}-${page}`;
        if (qnaCache[cacheKey]) {
            return qnaCache[cacheKey];
        }
        const response = await getQnaSearchResults(page, size, qnaSearchRequestBody);
        return {...response, cacheKey};
    }
);

const initialState = {
    carriers: [],
    carriersWith1555: [],
    thematics: [],
    thematicMap: {
        carriers: {},
        thematics: {}
    },
    geographies: {},
    selectedGeography: null,
    qnas: [],
    qnaCache: {},
    selectedCarrier: null,
    selectedThematic: null,
    selectedThematics: [],
    page: 1,
    totalPages: 0,
    status: 'idle',
    error: null,
    loading: false,
    qnaLoading: false,
    thematicsLoading: false,
    geographiesLoading: false,
    levelLoading: {}
};

const knowledgebaseSlice = createSlice({
    name: 'knowledgebase',
    initialState,
    reducers: {
        selectCarrier: (state, action) => {
            state.selectedCarrier = action.payload;
            state.selectedThematic = null;
            state.selectedGeography = null;
            state.selectedThematics = [];
            state.qnas = [];
            state.selectedGeography = null;
            state.levelLoading = {};
        },
        selectThematic: (state, action) => {
            const thematic = action.payload;
            const newSelectedThematics = [];
            if (thematic.parentId === null) {
                newSelectedThematics.push(thematic);
            } else {
                for (let i = 0; i < state.selectedThematics.length; i++) {
                    newSelectedThematics.push(state.selectedThematics[i]);
                    if (state.selectedThematics[i].id === thematic.parentId) {
                        break;
                    }
                }
                newSelectedThematics.push(thematic);
            }
            state.selectedThematics = newSelectedThematics;
            state.selectedThematic = thematic;
        },
        selectGeography: (state, action) => {
            state.selectedGeography = action.payload;
        },
        clearSelections: (state) => {
            state.selectedCarrier = null;
            state.selectedThematic = null;
            state.selectedThematics = [];
            state.qnas = [];
            state.selectedGeography = null;
            state.levelLoading = {};
        },
        clearQnas: (state) => {
            state.qnas = [];
            state.page = 1;
        },
        resetKnowledgeBaseExceptCarrier: (state) => {
            state.selectedThematic = null;
            state.selectedThematics = [];
            state.qnas = [];
            state.thematics = [];
            state.selectedGeography = null;
            state.levelLoading = {};
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setLevelLoading: (state, action) => {
            const {level, loading} = action.payload;
            if (!state.levelLoading) {
                state.levelLoading = {};
            }
            state.levelLoading[level] = loading;
        },
        popThematicStack: (state) => {
            const lastSelectedThematic = state.selectedThematics[state.selectedThematics.length - 1];
            if (state.selectedThematics.length !== 0) {
                state.selectedThematics.pop();
                if (lastSelectedThematic && lastSelectedThematic.id === 10010) {
                    state.selectedThematics = [];
                    state.selectedCarrier = null;
                }
                state.thematics = state.thematicMap.thematics[state.selectedThematics[state.selectedThematics.length - 1]?.id] || [];
                state.selectedThematic = null;
            } else {
                state.selectedCarrier = null;
                state.thematics = [];
                state.selectedThematics = [];
                state.qnas = [];
            }
        },
        navigateToThematicLevel: (state, action) => {
            const {index} = action.payload;
            if (index === 0) {
                state.selectedThematics = [];
                state.selectedThematic = null;
                state.thematics = state.thematicMap.carriers[state.selectedCarrier?.id] || [];
            } else {
                state.selectedThematics = state.selectedThematics.slice(0, index);
                state.selectedThematic = state.selectedThematics[index - 1];
                state.thematics = state.thematicMap.thematics[state.selectedThematic?.id] || [];
            }
            state.qnas = [];
            state.levelLoading = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCarriers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCarriers.fulfilled, (state, action) => {
                state.loading = false;
                state.carriers = action.payload.modified;
                state.carriersWith1555 = action.payload.original;
            })
            .addCase(fetchCarriers.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchGeographiesByCarrierId.pending, (state) => {
                state.geographiesLoading = true;
            })
            .addCase(fetchGeographiesByCarrierId.fulfilled, (state, action) => {
                state.geographiesLoading = false;
                const {carrierId, geographies} = action.payload;
                if (!state.geographies[carrierId]) {
                    state.geographies[carrierId] = {};
                }
                geographies.forEach((geography, index) => {
                    if (geography !== null) {
                        state.geographies[carrierId][index] = geography;
                    }
                });
            })
            .addCase(fetchGeographiesByCarrierId.rejected, (state) => {
                state.geographiesLoading = false;
            })
            .addCase(fetchThematicsByCarrierId.pending, (state, action) => {
                state.thematicsLoading = true;
                state.levelLoading[0] = true;  // Set loading for level 0
            })
            .addCase(fetchThematicsByCarrierId.fulfilled, (state, action) => {
                state.thematicsLoading = false;
                state.levelLoading[0] = false;  // Unset loading for level 0
                const {carrierId, thematics} = action.payload;
                const level1Thematics = thematics.filter(thematic => thematic.parentId === null);
                state.thematics = level1Thematics;
                state.thematicMap.carriers[carrierId] = level1Thematics;
            })
            .addCase(fetchThematicsByCarrierId.rejected, (state) => {
                state.thematicsLoading = false;
                state.levelLoading[0] = false;  // Unset loading for level 0
            })
            .addCase(fetchThematicsByParentId.pending, (state, action) => {
                const {arg: parentId} = action.meta;
                const level = state.selectedThematics.findIndex(t => t.id === parentId) + 1;
                state.thematicsLoading = true;
                state.levelLoading[level] = true;
            })
            .addCase(fetchThematicsByParentId.fulfilled, (state, action) => {
                const {parentId, thematics} = action.payload;
                const level = state.selectedThematics.findIndex(t => t.id === parentId) + 1;
                state.thematicsLoading = false;
                state.levelLoading[level] = false;  // Unset loading for specific level
                const childThematics = thematics.filter(thematic => thematic.parentId === parentId);
                state.thematics = childThematics;
                state.thematicMap.thematics[parentId] = childThematics;
            })
            .addCase(fetchThematicsByParentId.rejected, (state, action) => {
                const {arg: parentId} = action.meta;
                const level = state.selectedThematics.findIndex(t => t.id === parentId) + 1;
                state.thematicsLoading = false;
                state.levelLoading[level] = false;
            })
            .addCase(fetchQnaSearchResults.pending, (state) => {
                state.qnaLoading = true;
            })
            .addCase(fetchQnaSearchResults.fulfilled, (state, action) => {
                state.qnaLoading = false;
                state.qnas = action.payload.elements || [];
                state.totalPages = action.payload.totalPages || 0;
                state.qnaCache[action.payload.cacheKey] = action.payload;
            })
            .addCase(fetchQnaSearchResults.rejected, (state) => {
                state.qnaLoading = false;
            });
    },
});

export const {
    selectCarrier,
    selectThematic,
    selectGeography,
    clearSelections,
    resetKnowledgeBaseExceptCarrier,
    setPage,
    popThematicStack,
    navigateToThematicLevel,
    clearQnas,
    setLevelLoading
} = knowledgebaseSlice.actions;

export default knowledgebaseSlice.reducer;
export const knowledgebaseInitialState = initialState;
