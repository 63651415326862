import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

const FooterItem = ({ href, src, title }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', width: '100%' }}>
        <Box className="bg-white flex flex-col p-2 border h-24"
             sx={{ borderColor: '#003476', borderWidth: '1px', width: '100%' }}>
            <div className="flex items-center w-full" style={{ height: '100%' }}>
                <img
                    src={src}
                    alt={title}
                    className="mr-2"
                    style={{
                        width: '60px',
                        height: src.includes('6.png') ? undefined : '50px'
                    }}
                />
                <Typography variant="subtitle1" sx={{ color: '#003476', textAlign: 'center', flex: 1 }}>
                    {title}
                </Typography>
            </div>
        </Box>
    </a>
);

const FooterWrapper = ({ children }) => {
    const location = useLocation();

    return (
        <Box className="flex flex-col justify-between">
            <Box className="flex-grow">{children}</Box>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box>
                    <Fade in={true} timeout={1000}>
                        <Box className="bg-gray-200 w-full text-center px-4 py-8">
                            <Typography className="text-2xl font-bold" sx={{ color: '#003476' }}>
                                <span style={{ fontWeight: 'normal' }}>Ψηφιακές Υπηρεσίες</span> <strong>Gov.gr</strong>
                            </Typography>
                            <Grid container spacing={4} className="mt-4">
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/org/ypergasias" src="/logo/Footer/1.png"
                                                title="Υπουργείο Εργασίας και Κοινωνικής Ασφάλισης (ΥΠΕΚΥΠ)" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/org/efka" src="/logo/Footer/2.png"
                                                title="Ηλεκτρονικός Εθνικός Φορέας Κοινωνικής Ασφάλισης (e-ΕΦΚΑ)" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/org/dypa" src="/logo/Footer/3.png"
                                                title="Δημόσια Υπηρεσία Απασχόλησης (Δ.ΥΠ.Α.)" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/org/opeka" src="/logo/Footer/4.png"
                                                title="Οργανισμός Προνοιακών Επιδομάτων και Κοινωνικής Αλληλεγγύης (Ο.Π.Ε.Κ.Α.)" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/org/ep" src="/logo/Footer/5.png"
                                                title="Ανεξάρτητη Αρχή «Επιθεώρηση Εργασίας»" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/org/teka" src="/logo/Footer/6.png"
                                                title="Ταμείο Επικουρικής Κεφαλαιοποιητικής Ασφάλισης (ΤΕΚΑ)" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/org/mtpy" src="/logo/Footer/7.png"
                                                title="Μετοχικό Ταμείο Πολιτικών Υπαλλήλων (Μ.Τ.Π.Υ.)" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FooterItem href="https://www.gov.gr/upourgeia/upourgeio-ergasias-kai-koinonikon-upotheseon/nautiko-apomakhiko-tameio-nat" src="/logo/Footer/nat60x50.png"
                                                title="Ναυτικό Απομαχικό Ταμείο (ΝΑΤ)" />
                                </Grid>


                            </Grid>
                        </Box>
                    </Fade>
                    <Box className="bg-[#003476] text-white py-4">
                        <Box
                            className="container mx-auto flex flex-col lg:flex-row justify-between items-center text-center lg:text-left"
                            sx={{
                                alignItems: { xs: 'center', lg: 'start' },
                                textAlign: { xs: 'center', lg: 'start' },
                            }}
                        >
                            <Box className="w-full lg:w-1/4 p-4"
                                 sx={{
                                     display: 'flex',
                                     flexDirection: 'column',
                                     alignItems: { xs: 'center', lg: 'start' },
                                     textAlign: { xs: 'center', lg: 'start' },
                                 }}>
                                <List sx={{ listStyleType: 'none', p: 0, mb: 2 }}>
                                    <ListItem sx={{ p: 0 }}>
                                        <Link
                                            href="Files/prosopika dedomena 1555.pdf"
                                            target="_blank"
                                            sx={{ color: 'white', textDecoration: 'none' }}
                                        >
                                            Προσωπικά Δεδομένα
                                        </Link>
                                    </ListItem>
                                    <ListItem sx={{ p: 0 }}>
                                        <Link href="/odigies-xrisis" sx={{ color: 'white', textDecoration: 'none' }}>
                                            Οδηγίες Χρήσης
                                        </Link>
                                    </ListItem>
                                </List>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: { xs: 'center', lg: 'flex-start' },
                                    alignItems: 'center'
                                }}>
                                    <IconButton
                                        component="a"
                                        href="https://www.facebook.com/labourgovgr"
                                        target="_blank"
                                        aria-label="facebook - ανοίξτε σε νέα καρτέλα"
                                        sx={{ color: '#ffffff', fontSize: '25px', mr: 1, p: 0 }}
                                    >
                                        <FacebookIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        component="a"
                                        href="https://twitter.com/labourgovgr"
                                        target="_blank"
                                        aria-label="twitter - ανοίξτε σε νέα καρτέλα"
                                        sx={{ color: '#ffffff', fontSize: '25px', mr: 1, p: 0 }}
                                    >
                                        <TwitterIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        component="a"
                                        href="https://www.youtube.com/@HellenicMinistryOfLabour"
                                        target="_blank"
                                        aria-label="youtube - ανοίξτε σε νέα καρτέλα"
                                        sx={{ color: '#ffffff', fontSize: '25px', p: 0 }}
                                    >
                                        <YouTubeIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box className="w-full lg:w-1/4 p-4"
                                 sx={{
                                     display: 'flex',
                                     flexDirection: 'column',
                                     alignItems: { xs: 'center', lg: 'flex-start' },
                                     textAlign: { xs: 'center', lg: 'start' },
                                 }}>
                                <ul className="list-none p-0" style={{ textAlign: { xs: 'center', lg: 'start' } }}>
                                    <li className="mb-2">
                                        <a href="https://ypergasias.gov.gr/" target="_blank"
                                           className="link text-white">Υπουργείο Εργασίας και Κοινωνικής Ασφάλισης</a>
                                    </li>
                                    <li className="mb-2">
                                        <a href="https://1555.gov.gr/" target="_blank"
                                           className="link text-white">1555.gov.gr</a>
                                    </li>
                                </ul>
                            </Box>

                            <Box className="w-full lg:w-1/4 p-4 flex items-center justify-center">
                                <img src="logo/Footer/logo_ypergasias-1555_footer.png" alt="Υπουργείο Εργασίας και Κοινωνικής Ασφάλισης και 1555 footer logos" className="h-24" />
                            </Box>
                            <Box className="w-full lg:w-1/4 p-4 flex items-center justify-center">
                                <img src="logo/award.png" alt="Award logo" className="h-20" />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="bg-white p-4">
                        <Box className="container mx-auto flex items-center below-xl:w-full below-xl:h-full w-1/2 h-1/2">
                            <img src="logo/espalogo204.jpg" alt="ESPA logo" className="w-full h-full object-cover" />
                        </Box>

                    </Box>
                </Box>
            </Slide>
        </Box>
    );
};

export default FooterWrapper;
